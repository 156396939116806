<template>
    <div class="counter">
        <span class="counter-number__number rainbow-font">{{ number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</span>
        <span class="counter-number__label" v-html="label"></span>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";

export default {
    props: {
        goalNumber: {
            type: Number,
            required: true,
        },
        time: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const number = ref(0);
        const step = props.time / props.goalNumber;
        let addFaktor = 1;

        // edge case too many counts (thousands)
        if (props.goalNumber >= 100000) {
            addFaktor = 200;
        } else if (props.goalNumber > 10000) {
            addFaktor = 50;
        }

        const incrementNumber = () => {
            if (number.value >= props.goalNumber) return;
            number.value += addFaktor;
        };

        onMounted(() => {
            setInterval(incrementNumber, step);
        });

        watch(number, () => {
            if (number.value >= props.goalNumber) {
                clearInterval(incrementNumber);
            }
        });

        return {
            number,
        };
    },
};
</script>

<style scoped>
.counter {
    width: min-content;
    background-color: var(--item-background);
    padding: 12px;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    border: 1px solid var(--item-border);
}
.counter-number__label {
    color: var(--text-light);
}
.counter-number__number {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: var(--font-size-xxxl);
    font-weight: bold;
    margin-right: 4px;
    font-family: ClintonExtraBold;
}
</style>
