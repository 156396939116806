<template>
    <footer>
        <p class="footer__hashtag">
            <TypeWriter :words="['My social media channels', '#FeelFreeToFollow']" />
        </p>
        <div class="footer__socials">
            <SocialIcons style-class="footer__socials" />
        </div>
        <router-link class="no-animation" to="/impressum"
            ><p class="footer__link">{{ $t("navigation.imprint") }}</p></router-link
        >
        <p class="footer__copywrite">&copy; {{ new Date().getFullYear() }} Armin Novacek</p>
        <a class="no-animation" href="mailto:mail@arminnovacek.com"
            ><span class="footer__link"><em>mail@arminnovacek.com</em></span></a
        >
    </footer>
</template>

<script>
import TypeWriter from "./TypeWriter.vue";
import SocialIcons from "./SocialIcons.vue";

export default {
    components: {
        TypeWriter,
        SocialIcons,
    },
};
</script>

<style scoped>
a {
    text-decoration: none;
}
footer {
    text-align: center;
    font-size: var(--font-size-small);
    color: var(--disabled-gray);
    position: fixed;
    width: 100%;
    z-index: -10;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
}
.footer__socials {
    width: 250px;
    margin: 15px auto;
    color: black;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.footer__copywrite {
    margin: 20px 0 5px;
}
.footer__link {
    color: var(--text-dark) !important;
    margin: 0 0 28px;
}
</style>
