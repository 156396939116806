<template>
    <div class="labeled-item">
        <h2 class="labeled-item__label">{{ label }}</h2>
        <div class="languages__item" v-for="(c, index) of content" :key="index">
            <p class="languages__item--heading">{{ c.language }}</p>
            <p class="languages__item--subheading">{{ c.level }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ResumeLanguages",
    props: {
        label: {
            type: String,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.languages__item {
    padding-bottom: 10px;
}
.languages__item--heading {
    margin-bottom: 7px;
    font-size: var(--font-size-large);
    margin-top: 13px;
}
.languages__item--subheading {
    font-style: italic;
    margin: 0px;
    margin-bottom: 5px;
}
</style>
