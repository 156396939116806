<template>
    <div class="labeled-item">
        <h2 class="labeled-item__label">{{ label }}</h2>
        <div class="tools">
            <div class="tools-item" v-for="(c, index) of content" :key="index">
                <img class="tools-item__image" :src="getImgPath('resume', c.img_name)" :alt="c.img_name" loading="lazy" />
                <p class="tools-item__label">{{ c.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ResumeTools',
  props: {
    label: {
        type: String,
        required: true
    },
    content: {
        type: Object,
        required: true
    }
  }
}
</script>

<style scoped>
.tools {
    flex-flow: row wrap;
    justify-content: space-between;
    display: flex;
}
.tools-item {
    display: flex;
    flex-direction: column;
    padding: 10px 0 20px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 25%;
    align-items: center;
}
.tools-item__image {
    height: 50px;
}
.tools-item__label {
    font-size: var(--font-size-xs);
    padding: 0px 2px;
    word-break: break-word;
    text-align: center;
}
@media (max-width: 550px) {
    .tools-item__image {
        height: 40px;
    }
}
</style>