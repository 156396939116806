<template>
    <div v-if="!defaultOpen" class="gradient-accordion">
        <div
            v-if="!showSlot"
            class="gradient"
            :class="additionalStyleClasses"
            :style="{
                backgroundImage: `linear-gradient(transparent, ${this.backgroundColor} )`,
                height: `${!this.showSlot ? this.height : 'unset'}`,
            }"
        />
        <div
            class="gradient__slot"
            @click="showAccordion"
            :class="{ 'gradient__slot--full-height': showSlot }"
            :style="{ height: !this.showSlot ? this.height : 'unset' }"
        >
            <slot />
        </div>
    </div>
    <slot v-else />
</template>

<script>
export default {
    name: "GradientAccordion",
    props: {
        backgroundColor: {
            type: String,
            required: false,
            default: "var(--background)",
        },
        height: {
            type: String,
            required: false,
            default: "82px",
        },
        defaultOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
        additionalStyleClasses: {
            type: String,
            required: false,
            default: "",
        },
    },
    emits: ["showStatusChange"],
    data() {
        return {
            showSlot: false,
        };
    },
    methods: {
        closeAccordion() {
            this.showSlot = false;
            this.$emit("showStatusChange", false);
        },
        showAccordion() {
            this.showSlot = true;
            this.$emit("showStatusChange", true);
        },
    },
};
</script>

<style scoped>
.gradient {
    position: absolute;
    width: 90%;
    left: 0;
    cursor: pointer;
    pointer-events: none;
}
@media (min-width: 500px) {
    .gradient {
        width: 80%;
    }
}
.gradient__slot {
    overflow-y: hidden;
}
.gradient__slot--full-height {
    height: unset;
    overflow-y: auto;
}
.gradient--full-width {
    width: 100%;
}
.gradient-accordion {
    position: relative;
}
</style>
