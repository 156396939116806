<template>
    <div class="project-filters">
        <h2>
            <button
                v-if="showCloseButton"
                @click="$refs.gradientAccordion.closeAccordion()"
                class="unbutton project-filters__close-button"
            >
                <ios-close-icon w="30px" h="30px" />
            </button>
            {{ $t("projects.filterByTags") }}
            <span v-if="foundAmount" class="project-filter__found-projects">{{ this.foundAmount }}</span>
        </h2>
        <GradientAccordion
            :default-open="isFilterAccordionOpen"
            additional-style-classes="main__inner"
            height="73px"
            ref="gradientAccordion"
            @showStatusChange="setCloseButton"
        >
            <button
                v-for="filter of filters"
                :key="filter.name"
                @click="toggleFilter(filter.name)"
                :class="{ 'button--active': filter.active }"
                >
                {{ filter.name }}
            </button>
            <button v-if="showDeleteButton" @click="toggleOffAllFilters" class="project-filters__remove-all">
                {{ $t("projects.removeAllTags") }}
            </button>
        </GradientAccordion>
    </div>
</template>

<script>
import projectsDe from "../assets/texts/projects_de.json";
import GradientAccordion from "./GradientAccordion";
import IosCloseIcon from 'vue-ionicons/dist/ios-close.vue'
export default {
    name: "ProjectFilters",
    components: {
        GradientAccordion,
        IosCloseIcon
    },
    props: {
        foundAmount: {
            type: Number,
            required: false,
            default: null,
        },
    },
    computed: {
        lang() {
            return this.$store.state.lang;
        },
        showDeleteButton () {
            return this.urlFilters.length > 0;
        }
    },
    data() {
        return {
            filters: [],
            filter: {
                name: "",
                active: false,
            },
            urlFilters: [],
            isFilterAccordionOpen: false,
            showCloseButton: false,
            projects: projectsDe, // take one of the locales - tools are named the same
        };
    },
    mounted() {
        // fill the filters array
        this.setFilters();
    },
    methods: {
        setFilters() {
            this.urlFilters = this.$route.query.filter ? this.$route.query.filter.split(",") : [];
            for (let project of this.projects) {
                for (let tool of project.tools) {
                    if (!this.filters.some((filter) => filter.name === tool)) {
                        const filterOption = {
                            name: tool,
                            active: this.urlFilters.indexOf(tool) !== -1,
                        };
                        this.filters.push(filterOption);
                        // if at least one filter is active open the filter accordion
                        if (filterOption.active) {
                            this.isFilterAccordionOpen = true;
                            this.showCloseButton = true;
                        }
                    }
                }
            }
        },
        toggleFilter(filterName) {
            for (let filter of this.filters) {
                if (filter.name === filterName) {
                    filter.active = !filter.active;
                    this.toggleUrlParam(filterName);
                }
            }
        },
        toggleOffAllFilters () {
            this.filters.map((filter) => filter.active = false);
            this.urlFilters = [];
            this.$router.push({
                query: {
                    ...this.$route.query,
                    filter: undefined 
                },
            });
        },
        toggleUrlParam() {
            // filter the active filters
            this.urlFilters = this.filters
                .filter((filter) => {
                    if (filter.active) {
                        return filter;
                    }
                })
                .map((activeFilter) => activeFilter.name);
            // set the active filters in the url
            this.$router.push({
                query: { 
                    ...this.$route.query,
                    filter: this.urlFilters.length > 0 ? this.urlFilters.join() : undefined 
                },
            });
        },
        setCloseButton(value) {
            this.showCloseButton = value;
            this.isFilterAccordionOpen = value;
        },

    },
};
</script>

<style scoped>
.project-filters {
    margin-bottom: 20px;
}
h2 {
    margin-top: 4px;
    display: flex;
    align-items: center;
}
button {
    font-family: var(--main-font);
    font-size: var(--font-size-small);
    padding: 10px;
    color: white;
    background-color: var(--disabled-gray);
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s ease;
}
button:hover {
    filter: brightness(1.1);
}
.project-filters__remove-all {
    border: 1px solid var(--text-light);
    color: var(--text-light);
    background-color: transparent;
    padding: 9px;
}
.button--active {
    background-color: var(--text-dark);
}
.project-filter__found-projects {
    white-space: nowrap;
    background-image: var(--rainbow);
    font-size: var(--font-size-xs);
    color: var(--background);
    margin-left: 12px;
    font-weight: var(--normal);
    padding: 5px 10px;
    border-radius: 5px;
}
.project-filters__close-button {
    padding: 3px 8px;
    color: var(--text-dark);
    margin: 0 8px 0 0;
}
.project-filter__close {
    font-size: 16px;
}
</style>
