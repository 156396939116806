<template>
    <div class="badges">
        <span v-for="(badge, index) of sortedBadges" :key="index" class="badge" :class="{'badge--highlighted': isHighlighted(badge)}">{{ badge }}</span>
    </div>
</template>

<script>
export default {
    name: "Badges",
    props: {
        badges: {
            type: Array,
            required: true
        }
    },
    computed: {
        sortedBadges () {
            return [...this.badges].sort();
        }
    },
    methods: {
        isHighlighted (badge) {
            const filters = this.$route.query?.filter?.split(',') || [];
            return filters.includes(badge);
        }
    }
}
</script>

<style scoped>
.badges {
    height: min-content;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.badge {
    font-weight: bold;
    padding: 4px 7px;
    color: white;
    background-color: var(--disabled-gray);
    width: max-content;
    font-size: 14px;
    border-radius: 5px;
}
.badge--highlighted {
    background-color: var(--text-dark);
}
</style>