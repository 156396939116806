<template>
    <div>
        <div class="personal">
            <div class="personal__image-container">
                <img class="personal__image" src="@/assets/images/resume/profile-picture.webp" alt="Armin Novacek" loading="lazy" />
                <a 
                    class="personal__download-btn no-animation" 
                    href="https://arminnovacek.com/media/ARMIN_NOVACEK_LEBENSLAUF.pdf"
                    download="Lebenslauf_Armin_Novacek.pdf"
                    target="_blank"
                    :title="$t('resume.downloadCv')"
                >
                    <i class="far fa-address-card"></i>
                </a>
            </div>
            <h2 class="personal__title">Dipl.-Ing. Armin Novacek</h2>
            <div class="personal__container">
                <p><i class="fas fa-heartbeat personal__container--icon"></i>{{ $t("resume.birthdate") }}</p>
                <p><i class="fas fa-street-view personal__container--icon"></i>{{ $t("resume.location") }}</p>
                <p>
                    <i class="fas fa-paper-plane personal__container--icon"></i>
                    <a href="mailto:mail@arminnovacek.com">mail@arminnovacek.com</a>
                </p>
            </div>
        </div>
        <div class="counters">
            <ResumeCounter :goalNumber="yearsExperience" :time="2000" :label="$t('resume.yearsExperience')" />
            <ResumeCounter :goalNumber="projectsDone" :time="2000" :label="$t('resume.projectsDone')" />
            <ResumeCounter :goalNumber="hoursCoded" :time="2000" :label="$t('resume.hoursProgrammed')" />
            <ResumeCounter :goalNumber="100000" :time="2000" :label="$t('resume.plusLinesOfCode')" />
        </div>
        <template v-if="resume">
            <ResumeCollapsableItem
                :label="$t('resume.professionalExperience')"
                :button-text="$t('resume.jobProfile')"
                :content="resume.jobs"
            />
            <ResumeCollapsableItem
                :label="$t('resume.education')"
                :button-text="$t('resume.studyContent')"
                :content="resume.education"
            />
            <div class="form form--1-1">
                <ResumeLevelItem :label="$t('resume.programmingLanguages')" :content="resume.programming_languages" />
                <ResumeLanguages :label="$t('resume.languages')" :content="resume.languages" />
            </div>
            <ResumeTools label="Tools & Skills" :content="resume.tools" />
        </template>
    </div>
</template>

<script>
import resumeDe from "../assets/texts/resume_de.json";
import resumeEn from "../assets/texts/resume_en.json";
import projectsDe from "../assets/texts/projects_de.json";
import ResumeCollapsableItem from "../components/ResumeCollapsableItem";
import ResumeLevelItem from "../components/ResumeLevelItem";
import ResumeLanguages from "../components/ResumeLanguages";
import ResumeTools from "../components/ResumeTools";
import ResumeCounter from "../components/ResumeCounter.vue";

export default {
    name: "Resume",
    components: {
        ResumeCollapsableItem,
        ResumeLevelItem,
        ResumeTools,
        ResumeLanguages,
        ResumeCounter,
    },
    data() {
        return {
            resume: null,
            projects: projectsDe,
        };
    },
    computed: {
        projectsDone() {
            return this.projects.length;
        },
        yearsExperience() {
            const startDate = new Date("2017-09-01");
            const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // Taking leap years into account
            const currentDate = new Date();
            const differenceInMilliseconds = currentDate - startDate;
            const years = differenceInMilliseconds / millisecondsPerYear;
            return years;
        },
        hoursCoded() {
            const workHoursPerYear = 7.5 * 5 * 56; // hours per work day * days per week * weeks per year
            const hours = this.yearsExperience * workHoursPerYear;
            return Math.floor(hours);
        },
        lang() {
            return this.$store.state.lang;
        },
    },
    watch: {
        lang(selected) {
            this.resume = selected === "de" ? resumeDe : resumeEn;
        },
    },
    mounted() {
        // set animation for window scroll
        this.setScrollAnim([".labeled-item", ".counters"]);

        this.resume = this.lang === "de" ? resumeDe : resumeEn;
    },
};
</script>

<style>
.labeled-item {
    background-color: var(--item-background);
    padding: 30px;
    padding-top: 35px;
    height: min-content;
    border-radius: 15px;
    margin-top: 55px;
    margin-bottom: 20px;
    border: 1px solid var(--item-border);
}
.labeled-item__label {
    margin-top: -54px;
    font-size: var(--font-size-xxxl);
}
</style>

<style scoped>
.personal__image {
    width: 200px;
    border-radius: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}
.personal__title {
    text-align: center;
    line-height: 30px;
}
.personal__container {
    width: 250px;
    margin: auto;
}
.personal__container--icon {
    margin-right: 20px;
    fill: var(--text-light);
}
.personal__image-container {
    position: relative;
}
.personal__download-btn {
    position: absolute;
    bottom: -15px;
    left: calc(50% + 65px);
    border-radius: 25px;
    width: 50px;
    height: 50px;
    background-image: var(--rainbow);
    color: var(--background);
    font-size: var(--font-size-xxl);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.personal__download-btn:hover {
    filter: brightness(1.05);
}
.counters {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}
@media (max-width: 800px) {
    .counters {
        overflow-x: scroll;
        justify-content: flex-start;
    }
}
</style>
