import { createStore } from "vuex";
import { changeColorTheme, setLangInStorage } from "../helper";

// Create a new store instance.
const store = createStore({
    state() {
        return {
            lightTheme: false,
            showFlyout: false,
            lang: 'de'
        };
    },
    mutations: {
        changeColorTheme(state) {
            // set the variable in the store
            state.lightTheme = !state.lightTheme;

            // actually change the Colors (helper function - not recursive)
            changeColorTheme(state.lightTheme);
        },
        setFlyoutVisibility(state, show) {
            // set the visibility of the mobile menu
            state.showFlyout = show;
        },
        changeLang(state) {
            state.lang = state.lang === 'de' ? 'en' : 'de';
            setLangInStorage(state.lang);
        }
    },
});

// color theme switch when reloading the page
const useLightTheme = JSON.parse(localStorage.getItem("lightTheme"));
if (useLightTheme) {
    store.commit("changeColorTheme");
}

// get the lang from storage
const setEnglish = localStorage.getItem("lang") === 'en';
if (setEnglish) {
    store.commit("changeLang");
}

export default store;