<template>
    <div class="projects">
        <ProjectFilters :found-amount="projectsFoundAmount" />
        <ProjectSearch />
        <div class="project-container">
            <template v-if="filteredProjects.length > 0">
                <Card
                    v-for="(project, index) of filteredProjects"
                    :key="index"
                    :project="project"
                    :id="project.id"
                    @image-viewer-visible="setImageViewerVisibility"
                />
            </template>
            <p v-else class="project-container--empty">{{ $t('projects.noSearchResultsFor') }} »{{ this.urlSearchWord }}«</p>
        </div>
    </div>
</template>

<script>
import projectsDe from "../assets/texts/projects_de.json";
import projectsEn from "../assets/texts/projects_en.json";
import Card from "../components/Card.vue";
import ProjectFilters from "../components/ProjectFilters.vue";
import ProjectSearch from "../components/ProjectSearch.vue";

export default {
    name: "Projects",
    components: {
        Card,
        ProjectFilters,
        ProjectSearch
    },
    data() {
        return {
            projects: []
        };
    },
    emits: ["image-viewer-visible"],
    mounted() {
        // set animations for window scroll
        this.setScrollAnim([".card"]);
        this.projects = this.lang === "de" ? projectsDe : projectsEn;
    },
    watch: {
        lang(selected) {
            this.projects = selected === "de" ? projectsDe : projectsEn;
        },
    },
    computed: {
        urlSearchWord () {
            return this.$route.query.s;
        },
        urlFilters() {
            return this.$route.query.filter ? this.$route.query.filter.split(",") : [];
        },
        filteredProjects() {
            // no filters and no search word inside url
            if (this.urlFilters.length === 0 && !this.urlSearchWord) {
                return this.projects;
            }
            
            // add projects which are matches for the filters
            const filterList = this.projects.filter((project) =>
                this.urlFilters.some((tool) => project.tools.indexOf(tool) !== -1)
            );

            // add projects which are matching the search word
            const searchList = this.projects.filter((project) => {
                if (this.urlSearchWord?.trim()) {
                    // title includes word
                    if (project.title.toLowerCase().includes(this.urlSearchWord.toLowerCase())) {
                        return true;
                    }
                    // description includes word
                    if (project.description.toLowerCase().includes(this.urlSearchWord.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            });

            // no matches found
            if (filterList.length === 0 && searchList.length === 0 && !this.urlSearchWord) {
                return this.projects;
            }
            return [...filterList, ...searchList];
        },
        projectsFoundAmount() {
            return this.filteredProjects.length;
        },
        lang() {
            return this.$store.state.lang;
        },
    },
    methods: {
        setImageViewerVisibility(visible, source, currentSlide) {
            this.$emit("image-viewer-visible", visible, source, currentSlide);
        },
    },
};
</script>

<style scoped>
.project-container {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
}
.project-container--empty {
    color: var(--text-dark);
}
@media (max-width: 900px) {
    .project-container {
        grid-template-columns: 1fr;
    }
}
</style>
